<template>
  <div style="display:flex;position: relative;">
    <el-dialog v-model="choiceGiftDialog" title="选择赠品" width="1200" draggable>
      <el-tabs class="demo-tabs" style="margin-top: -20px" v-model="activeNames" @tab-change="tabChange" @tab-click="handleClick" stretch>
        <el-tab-pane name="lesson" label="选择要赠送的课程">
          <div>
            <el-form label-width="70px" class="demo-ruleForm" style="display:flex">
              <el-row>
                <el-col :span="6">
                  <el-input size="small" v-model="searchCard.name" placeholder="查询卡片" style="width:120px"></el-input>
                </el-col>
                <el-col :span="6">
                  <el-select size="small" v-model="searchCard.authority" placeholder="可视权限" class="handle-select mr10"
                             style="width:120px;">
                    <el-option v-for="(item, index) in choice.authorityList " :key="index"
                               :label="item.dictLabel"
                               :value="item.dictValue"
                    ></el-option>
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-select size="small" v-model="searchCard.subtractCourseWay" placeholder="消课方式"
                             class="handle-select mr10" style="width:120px;">
                    <el-option v-for="(item,index) in choice.subtractCourseWayList " :key="index"
                               :label="item.dictLabel"
                               :value="item.dictValue"
                    ></el-option>
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-select size="small" v-model="searchCard.setDateWay" placeholder="日期设置" class="handle-select mr10"
                             style="width:120px;">
                    <el-option v-for="(item,index) in choice.setDateWayList " :key="index"
                               :label="item.dictLabel"
                               :value="item.dictValue"
                    ></el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-button size="small" style="display:inline;margin-left: 20px;" @click="searchCardList">查询</el-button>
            </el-form>
            <el-table
                style="margin-top: 10px"
                height="440px"
                :row-style="{height:2+'px'}"
                :cell-style="{color:'#909399',fontSize:'13px',padding:'7px 0px'}"
                :data="courseCardTable" border>
              <el-table-column align="center" show-overflow-tooltip="true" prop="name" label="卡片名称"/>
              <el-table-column align="center" show-overflow-tooltip="true" prop="subtractCourseWayName" label="消课方式">
              </el-table-column>
              <el-table-column align="center" show-overflow-tooltip="true" label="日期设置">
                <template #default="scope">
                  <el-tooltip class="box-item" effect="dark" placement="top">
                    <template #content>
                      <div v-if="scope.row.setDateWay == 1">开始日期：{{ scope.row.startDate }}
                        <br/>结束日期：{{ scope.row.endDate }}
                      </div>
                      <div v-if="scope.row.setDateWay == 2">使用后{{ scope.row.howManyDay }}天内有效</div>
                      <div v-if="scope.row.setDateWay == 3">购买后{{ scope.row.howManyDay }}天内有效</div>
                      <div v-if="scope.row.setDateWay == 4">{{ scope.row.startDate }} 至 {{ scope.row.endDate }}
                        期间内可用{{ scope.row.howManyDay }}天
                      </div>
                    </template>
                    <el-tag circle>{{ scope.row.setDateWayName }}</el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column align="center" show-overflow-tooltip="true" prop="authorityName" label="可视权限">
              </el-table-column>
              <el-table-column align="center" show-overflow-tooltip="true" label="价格">
                <template #default="scope">{{ scope.row.money }} 元</template>
              </el-table-column>
              <el-table-column label="课程数量" align="center" show-overflow-tooltip="true">
                <template #default="scope">
                  <div>
                    {{ scope.row.subtractCourseWay != 1 ? '期卡' : scope.row.courseNumber + '次' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="状态" width="80px">
                <template #default="scope">
                  <el-switch
                      disabled
                      v-model="scope.row.state"
                      active-value="开启"
                      inactive-value="禁用"
                  >
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80px">
                <template #default="scope">
                  <el-button size="small" type="text" @click="confirm('course',scope.$index, scope.row)">添加
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane name="equipment" label="选择要赠送的器材">
          <div>
            <el-form label-width="70px" class="demo-ruleForm" style="display:flex">
                  <el-input size="small" v-model="searchEquipment.keyword" placeholder="器材名称" style="width:120px"></el-input>
              <el-button size="small" style="display:inline;margin-left: 20px;" @click="searchEquipmentList">查询</el-button>
            </el-form>
            <el-table
                height="440px"
                :row-style="{height:2+'px'}"
                :cell-style="{color:'#909399',fontSize:'13px',padding:'7px 0px'}"
                :data="courseEquipmentTable"  style="margin-top: 10px" border>
              <el-table-column prop="name" label="器材名称"/>
              <el-table-column label="库存量">
                <template #default="scope">
                  <el-tag :type="scope.row.num < 10 ? 'danger' : ''">{{ scope.row.num }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" sortable/>
              <el-table-column label="所属城市">
                <template #default="scope">{{ scope.row.regionName }}</template>
              </el-table-column>
              <el-table-column label="类型">
                <template #default="scope">{{ scope.row.flagName }}</template>
              </el-table-column>
              <el-table-column label="价格" prop="money">
                <template #default="scope"><span style="color:darkgrey"> {{scope.row.money ? scope.row.money : '暂无价格'}}</span></template>
              </el-table-column>
              <el-table-column label="状态" width="80px">
                <template #default="scope">
                  <div v-show="false">{{ scope.row.state }}</div>
                  <el-switch
                      disabled
                      v-model="scope.row.state"
                      :active-value='1'
                      :inactive-value='0'
                  >
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80px">
                <template #default="scope">
                  <el-button size="small" type="text" @click="confirm('equipment',scope.$index, scope.row)">添加
                  </el-button>
                </template>
              </el-table-column>
          </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <div style="width: 100%;">
      <el-button type="primary" @click="choiceGiftDialog = true" style="margin-bottom: 10px">
        添加赠品
      </el-button>
      <el-table
          style="margin-top: 10px"
          height="100%"
          :row-style="{height:2+'px'}"
          :cell-style="{color:'#909399',fontSize:'13px',padding:'7px 0px'}"
          :data="presenteList" border>
        <el-table-column label="赠送名称" prop="name"></el-table-column>
        <el-table-column label="赠送类型" prop="type">
          <template #default="scope">
            {{ scope.row.type == 1 ? '课程' : ' 器材' }}
          </template>
        </el-table-column>
        <el-table-column label="详情" align="center">
          <template>
            <el-button type="primary">详情</el-button>
          </template>
        </el-table-column>
        <el-table-column label="系数" align="center">
          <template #default="scope">
            <el-input-number v-model="scope.row.coefficient" size="small" :min="0" :step="0.5" :max="100"/>
          </template>
        </el-table-column>
        <el-table-column label="可额外赠送数量" align="center">
          <template #default="scope">
            <el-input-number v-model="scope.row.promotionGiftNumber" size="small" :min="0" :step="0.5" :max="100"/>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80px">
          <template #default="scope">
            <span size="small" v-if="!scope.row?.disAbled" style="color:red;font-size:13px;cursor: pointer;"
                  @click="delPresente(scope.$index)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import {equList} from "@/api/equipment";
import {searchCard} from '@/api/card'
import {selectAreaByExpress} from "@/api/area";

export default {
  props: {
    editGift: Object,
    areaType: String,
    choice: Object,
    roleId: Number,
    areaId: Number
  },
  data() {
    return {
      choiceGiftDialog: false,
      equipment: 'equipment',
      searchEquipment: {},
      regionList: [],
      activeNames: 'lesson',
      searchCard: { roleId: null },
      courseCardTable: [],
      courseEquipmentTable: [],
      presenteList:[]
    }
  },
  created() {
    selectAreaByExpress().then(res => {
      if (res.code == 200) {
        this.regionList = res.data
        this.$emit('regionList', this.regionList)
      } else {
        this.$message.error("获取城市数据失败");
      }
    })
  },
  methods: {
    //删除赠品
    delPresente(index) {
      this.presenteList.splice(index, 1)
    },
    //确定赠品
    confirm(e, index, row) {
      this.addPresente(e, row);
    },
    addPresente(e, row) {
      if (e == 'course') {
        let presente = {}
        presente.name = row.name
        presente.type = 1
        presente.giftNumber = 0
        presente.coefficient = 0
        presente.productId = row.id
        presente.promotionGiftNumber = 0
        this.presenteList.push(presente)
      } else {
        if (e == 'equipment') {
          let presente = {}
          presente.name = row.name
          presente.type = 2
          presente.giftNumber = 1
          presente.productId = row.id
          this.presenteList.push(presente)
        }
      }
    },
    handleClick() {
      // console.log(tab, event);
    },
    //查询卡片
    searchCardList() {
      if (this.searchCard.roleId == null){
        this.$message.error('请先选择课程角色！');
        return false;
      }
      searchCard(this.searchCard).then(res => {
        if (res.code == 200) {
          this.courseCardTable = res.data
        } else {
          this.$message.error('查询失败')
        }
      })
    },
    searchEquipmentList() {
      equList(this.searchEquipment).then(res => {
        if (res.code == 200) {
          this.courseEquipmentTable = res.data.records;
        } else {
          this.$message.error("获取表格数据失败");
        }
      })
    }
  },
  watch: {
    presenteList: {
      handler: function (newVal) {
        if (newVal.length>0){
          console.log("赠品数量发生调整")
          console.log(newVal)
          this.$emit('getInform', newVal)
        }
      },
      deep: true, // 深度监听
      immediate: true, //立即执行
    },
    roleId:{
      handler: function (newVal) {
        this.searchCard.roleId = newVal || null
      },
      deep: true, // 深度监听
      immediate: true, //立即执行
    },
    areaId:{
      handler: function (newVal) {
        this.searchEquipment.regionId = newVal || null
      },
      deep: true, // 深度监听
      immediate: true, //立即执行
    },
    areaType: {
      handler: function (newVal) {
        this.searchEquipment.flag = newVal || 0
      },
      deep: true, // 深度监听
      immediate: true, //立即执行
    },
    editGift: {
      handler: function (newVal) {
        if (newVal.length>0){
          this.presenteList = newVal
        }
      },
      deep: true, // 深度监听
      immediate: true, //立即执行
    }
  },
}

</script>
<style>
.handle-select {
  width: 120px;
}

.mr10 {
  margin-right: 10px;
}
</style>                                                                                               