<template>
  <div style="padding: 20px">
    <div>
      <el-button icon="Plus" type="primary" class="handle-del mr10" @click="createCard">新增</el-button>
      <el-input placeholder="请输入卡片名字" v-model="query.cardName" style="width: 150px;margin-right: 10px"></el-input>
      <el-select placeholder="城市类型" style="width: 120px;margin-right: 10px" v-model="query.areaType">
        <div>
          <el-option label="全部"></el-option>
          <el-option label="中招体育" value="1"></el-option>
          <el-option label="少年" value="2"></el-option>
          <el-option label="高考体育" value="4"></el-option>
        </div>
      </el-select>
      <el-select style="width: 150px" v-model="query.areaId" placeholder="城市" class="handle-select mr10">
        <el-option
            v-for="(item,index) in regionList" :key="index"
            :label="item.name"
            :value="item.id"></el-option>
      </el-select>
      <el-select
          clearable
          v-model="query.role"
          placeholder="可视权限"
          class="handle-select mr10"
      >
        <el-option v-for="(item,index) in choice.authorityList " :key="index"
                   :label="item.dictLabel"
                   :value="item.dictValue"
        ></el-option>
      </el-select>
      <el-select
          clearable
          v-model="query.state"
          placeholder="卡片状态"
          class="handle-select mr10"
      >
        <el-option label="开启" value="开启"></el-option>
        <el-option label="关闭" value="关闭"></el-option>
        <el-option label="禁用" value="禁用"></el-option>
      </el-select>
      <el-date-picker
          style="width: 260px;margin-right: 10px"
          v-model="query.dateList"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
      >
      </el-date-picker>
      <el-button
          type="primary"
          icon="Search"
          style="margin-left: 10px"
          @click="handleSearch">搜索
      </el-button>
      <el-button type="info" icon="Search" @click="reset">重置</el-button>
      <el-popconfirm @confirm="exportExcel" title="确定要导出吗？">
        <template #reference>
          <el-button type="primary" icon="Download">导出</el-button>
        </template>
      </el-popconfirm>
    </div>
    <table-card ref="tableCard" :card-list="cardList" @selectCourse="selectSpecByCardId"
                @combinationDialog="openCombinationDialog" :total="total"
                :PageIndex="query.pageIndex" @sendPageIndex="sendPageIndex"/>
    <create-card ref="createCard" :visual="choice.authorityList" @success="success"/>
    <course ref="course" :card="cardItem" @selectCourse="selectSpecByCardId" :choice="choice"/>
    <combination ref="combination" :choice="choice"/>
  </div>
</template>

<script>
import {selectDictByType} from "@/api/dict"
import {selectAreaByExpress} from "@/api/area";
import {exportExcel, getCardList, selectSpecByCardId} from "@/api/card";
import createCard from "./components/createCard";
import tableCard from "./components/TableCard";
import course from "./components/Course";
import Combination from "./components/Combination";
import bus from '@/utils/bus.js'

export default {
  components: {
    createCard,
    tableCard,
    course, Combination
  },
  name: "Card",
  data() {
    return {
      query: {
        areaType: '',
        areaId: '',
        state: '',
        role: '',
        pageIndex: 1,
        pageSize: 10,
        dateList: [],
      },
      total: 0,
      choice: {
        authorityList: [],
        subtractCourseWayList: [],
        setDateWayList: []
      },
      regionList: [],
      cardList: [],
      cardItem: {},
    }
  },
  created() {
    this.getCardLists();
    selectAreaByExpress().then(res => {
      if (res.code == 200) {
        this.regionList = res.data
      } else {
        this.$message.error("获取城市数据失败");
      }
    })
    selectDictByType("subtractCourseWay").then(res => {
      if (res.code == 200) {
        this.choice.subtractCourseWayList = res.data
      } else {
        this.$message.error("获取数据失败");
      }
    })
    selectDictByType("setDateWay").then(res => {
      if (res.code == 200) {
        this.choice.setDateWayList = res.data
      } else {
        this.$message.error("获取数据失败");
      }
    })
    selectDictByType("cardLookRole").then(res => {
      if (res.code == 200) {
        this.choice.authorityList = res.data
      } else {
        this.$message.error("获取数据失败");
      }
    })
  },
  methods: {
    exportExcel() {
      exportExcel(this.query).then(res => {
        let blob = new Blob([res], {type: "application/vnd.ms-excel"});
        let objectUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');//我们用模拟q标签点击事件
        const fname = '卡片列表'; //下载文件的名字
        link.href = objectUrl;
        link.setAttribute('download', fname);
        document.body.appendChild(link);
        /**
         * 在这期间，我们可以做自己界面的反应逻辑
         **/
        link.click();//点击
        document.body.removeChild(link); // 下载完成移除元素
        window.URL.revokeObjectURL(URL); // 释放掉blob对象
      })
    },
    sendPageIndex(e) {
      this.query.pageIndex = e
      this.getCardLists()
    },
    //子组件添加成功刷新页面
    success() {
      this.getCardLists()
    },
    //选择玄
    //重置
    reset() {
      Object.keys(this.query).forEach(key => {
        this.query[key] = ''
      })
      this.query.pageIndex = 1
      this.query.pageSize = 10
    },
    //搜索
    handleSearch() {
      this.query.pageIndex = 1
      this.getCardLists()
    },
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      return time;
    },
    async getCardLists() {
      this.$nextTick(() => {
        this.$refs.tableCard.loadingDia = true
      })
      if (this.query.dateList.length >= 2) {
        this.query.startDate = this.dateFormat(this.query.dateList[0]);
        this.query.endDate = this.dateFormat(this.query.dateList[1]);
      } else {
        this.query.startDate = null;
        this.query.endDate = null;
      }

      await getCardList(this.query).then(res => {
        if (res.code == 200) {
          this.cardList = res.data.records;
          this.total = res.data.total
          this.$refs.tableCard.loadingDia = false
        } else {
          this.$message.error("获取卡片列表失败！");
        }
      })
    },
    /**
     * 根据卡片id查询卡片规格、属性值对应的课程
     */
    selectSpecByCardId(card) {
      selectSpecByCardId(card.id).then(res => {
        if (res.code == 200) {
          card.specList = res.data.specList;
        } else {
          this.$message.error("获取卡片列表失败！");
        }
      })
      this.cardItem = card;
      console.log("-----加载-cardRule详情信息:")
      console.log(card);
      this.$refs.course.openDialog();
    },
    /**
     * 打开添加组合卡弹出框
     */
    openCombinationDialog(card) {
      console.log(card)
      this.$refs.combination.openDialog(card);
    },
    /**
     * 打开添加卡片弹出框
     */
    createCard() {
      this.$refs.createCard.openDialog();
    }
  },
  mounted() {
    bus.on('success', (value) => {
      if (value == 'success') {
        this.getCardLists();
      }
    })
  }
}
</script>

<style scoped>


.mr10 {
  margin-right: 10px;
}

.handle-select {
  width: 120px;
}
</style>


