<template>
    <div>
      <el-dialog
          v-model="createCardDialog"
          title="编辑卡片"
          width="1000px"
          align-center
      >
        <div style="margin-right: 50px">
          <el-form :model="editCard" :rules="cardRules" ref="cardRules" label-width="120px" class="demo-ruleForm">
            <el-row>
              <el-col :span="8">
                <el-form-item class="itemWih" label="卡片名称" prop="name">
                  <el-input v-model="editCard.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item class="itemWih"  label="城市类型" prop="areaType">
                  <el-select v-model="editCard.areaType" disabled>
                        <el-option label="中招体育" value="1"></el-option>
                        <el-option label="少年" value="2"></el-option>
                        <el-option label="高考体育" value="4"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item class="itemWih" label="所属城市" prop="name">
                  <el-select    v-model="editCard.areaId"  disabled>
                    <el-option
                        style="width: 160px"
                        v-for="(item, index) in affiliationCampus"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="上架日期" prop="secKillTime">
                  <el-date-picker  type="date" style="width:184px"
                                   format="YYYY-MM-DD"
                                   value-format="YYYY-MM-DD"
                  v-model="editCard.secKillTime" placeholder="上架日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="下架日期" prop="soldEndTime">
                  <el-date-picker  type="date" style="width:184px"
                                   format="YYYY-MM-DD"
                                   value-format="YYYY-MM-DD"
                  v-model="editCard.soldEndTime" placeholder="下架日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="可视权限" prop="role">
                  <el-select  v-model="editCard.role">
                    <el-option label="全部可见" :value=0></el-option>
                    <el-option label="教练可见" :value=1></el-option>
                    <el-option label="家长可见" :value=2></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
                <el-col :span="16">
                  <el-form-item class="itemWih" label="卡片描述" prop="briefing">
                    <el-input style="width: 488px;" :rows="3" v-model="editCard.briefing"
                      prop="briefing"    placeholder="请输入描述信息" show-word-limit type="textarea"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="3">
                  <el-form-item class="itemWih" label="缩略图" prop="miniPicture">
                    <div style="display:flex">
                      <el-upload
                          ref='upload'
                          class="upload"
                          :http-request="uploadCardOmit"
                          :before-upload="beforeAvatarUpload"
                          :on-remove="handleRemoveMin"
                          :class="{ uoloadSty: thisthisImageminiPicture == null ? true : false, disUoloadSty: thisthisImageminiPicture != null ? true : false }"
                          :limit=1
                          list-type="picture-card">
                       <el-button icon="Plus" text />
                    </el-upload>
                      <span class="demo-image" style='margin-left:10px;' >         
                      <el-image style="width: 80px; height: 80px" :src="miniPictureUrl" />
                      </span>
                    </div>
              
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="16"
                >
                <el-form-item label="卡片规格" prop="ruleId">
                  <el-radio-group v-model="editCard.ruleId" class="ml-4"  disabled>
                  <el-radio :label="1" size="large">单规格</el-radio>
                  <el-radio :label="2" size="large">多规格</el-radio>
               </el-radio-group>
                </el-form-item>
                  <!-- <el-form-item class="itemWih" label="卡片描述" prop="briefing">
                    <el-input style="width: 488px;" :rows="3" v-model="editCard.briefing"
                      prop="briefing"    placeholder="请输入描述信息" show-word-limit type="textarea"
                    />
                  </el-form-item> -->
                </el-col>
                <el-col :span="3">
                  <el-form-item class="itemWih" label="详情图" prop="productPicture">
                    <div style="display:flex"> 
                    <el-upload
                          ref='upload'
                          class="upload"
                          :http-request="uploadCardProduct"
                          :before-upload="beforeAvatarUpload"
                          :on-remove="handleRemovePro"
                          :class="{ uoloadSty: thisImageproductPicture == null ? true : false, disUoloadSty: thisImageproductPicture != null ? true : false }"
                          :limit="1"
                          list-type="picture-card">
                       <el-button icon="Plus" text />
                      </el-upload>
                      <span class="demo-image" style='margin-left: 10px;' >         
                      <el-image style="width: 80px; height: 80px" :src="productPictureUrl" />
                      </span>
                    </div>
                  </el-form-item>
                 
                </el-col>
              </el-row>
          </el-form>

        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="createCardDialog = false">取消</el-button>
            <el-button type="primary" @click="confireditCard">确定</el-button>
          </span>
        </template>
      </el-dialog>
  
    </div>
  </template>
  
  <script>
    import { uploadImage} from '@/api/helpManual'
    import {selectAreaByExpress} from "@/api/area";
    import {updateCardById}  from '@/api/card';
    import bus from '@/utils/bus.js'
  export default {
    name: "EditCard",
    data() {
      return {
        miniPictureUrl:'',
        productPictureUrl:'',
        thisImageproductPicture:null,
        thisthisImageminiPicture:null,
        affiliationCampus:[],
        createCardDialog:false,
        editCard:{
          name:"",
          miniPicture:"",
          productPicture:"",
          areaId:null,
          secKillTime:null,
          soldEndTime:null,
          areaType:null,
          role:null,
          content:null,
          ruleId:1,
        },
        cardRules: {
          name: [{
            required: true,
            message: '请选择卡片名称！',
            trigger: 'blur'
          },],
          areaType: [{
            required: true,
            message: '请选择城市类型！',
            trigger: 'focus'
          },],
          areaId: [{
            required: true,
            message: '请选择所属城市！',
            trigger:'change'
          }],
          briefing: [{
            required: true,
            message: '请添加卡片描述！',
            trigger: 'blur'
          }],
          role: [{
            required: true,
            message: '请选择可视权限！',
            trigger: 'change'
          }],
          productPicture: [{
            required: true,
            message: '请添加详情图！',
            trigger: 'change'
          }],
          miniPicture: [{
            required: true,
            message: '请添加省略图！',
            trigger: 'change'
          }],
          secKillTime: [{
            required: true,
            message: '请选择上架日期！',
            trigger: 'change'
          }],
          soldEndTime: [{
            required: true,
            message: '请选择下架日期！',
            trigger: 'change'
          }],
        },
      }
    },
    created(){ 
        this.getAffiliationCampus()
    },
    methods: {
        //接受父组件传过来的值
      getTable(data){ 
          this.createCardDialog = true
          this.editCard = JSON.parse(JSON.stringify(data))
          this.miniPictureUrl= data.miniPicture
          this.productPictureUrl = data.productPicture                   
        },
   
      handleRemoveMin(){ 
          this.thisthisImageminiPicture = null
      },
      handleRemovePro(){ 
          this.thisImageproductPicture = null
      },
      confireditCard(){ 
        this.$refs.cardRules.validate((valid)=>{   
          if(valid){ 
           this.editCard.secKillTime = this.dateFormat(this.editCard.secKillTime)
           this.editCard.soldEndTime = this.dateFormat(this.editCard.soldEndTime)
            updateCardById(this.editCard).then(res=>{ 
              if(res.code==200){ 
                this.createCardDialog = false
                bus.emit('success','success')
                this.$message.success('更新成功！');   
              }else{ 
                this.$message.error('更新失败！');
              }
            })
          }
        })
      },
      //上传省略图片
      async uploadCardOmit(file) {
        this.thisthisImageminiPicture = file.file
        this.editCard.miniPicture = await this.uploadImg(file)
      },

      async uploadCardProduct(file) {
        this.thisImageproductPicture = file.file
        this.editCard.productPicture = await this.uploadImg(file)
      },
      async uploadImg(file) {
        let url = null
        const data = new FormData()
        data.append(
          'multipartFile', file.file
        )
        data.append(
          'flag', 'card'
        )
        data.append(
          'fileName', file.file.name
        )
        await uploadImage(data).then(res => {
          if (res.code == 200) {
            url = res.data
          } else {
            this.$message.warning('图片上传失败');
          }
        })
        return url
      },
      dateFormat(val) {
        var date = new Date(val);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        var d = date.getDate();
        d = d < 10 ? "0" + d : d;
        const time = parseInt(y) + "-" + parseInt(m) + "-" + parseInt(d);
        return time;
      },
      //获取所属校区
      getAffiliationCampus() {
        selectAreaByExpress().then(res => {
          if (res.code == 200) {
            this.affiliationCampus = res.data
          }
        })
      },
      openDialog() {
        this.createCardDialog = true;
      },
  
    }
  }
  </script>
  
  <style scoped>
  /deep/ .disUoloadSty  .el-upload--picture-card{
    display:none !important;   /* 上传按钮隐藏 */
  }
  
  /deep/ .el-upload--picture-card{
    width: 80px;
    height: 80px;
  }
  /deep/ .el-upload{
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item{
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail{
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
  /deep/ .avatar{
    width: 80px;
    height: 80px;
  }
  </style>