<template>
    <div class="websiteEditorElem">
      <div id="websiteEditorElem" style="height:400px;background: #ffffff;text-align: left"></div>
    </div>
</template>
<script>
  import E from 'wangeditor'
  import {  uploadImage,
      } from '@/api/helpManual'
  export default{
      props: {
        addHtml:String
      },
      data(){
          return{
              phoneEditor:''
          }
      },
    mounted(){
      this.phoneEditor = new E('#websiteEditorElem')
      this.phoneEditor.config.uploadImgShowBase64 = false
      this.phoneEditor.config.onchange = (html) => {
          // 打印输入的内容
         this.$emit('getHtml',html)
      }
      this.phoneEditor.config.fontSizes = {
      'x-small': { name: '10px', value: '1' },
      'small': { name: '11px', value: '2' },
      'normal': { name: '12px', value: '3' },
      'large': { name: '13px', value: '4' },
      'x-large': { name: '14px', value: '5' },
      'xx-large': { name: '15px', value: '6' },
      'xxx-large': { name: '16px', value: '7' },
      }
      this.phoneEditor.config.showLinkImg = false
      this.phoneEditor.config.uploadImgShowBase64 = false
      this.phoneEditor.config.customUploadImg = function (file, insertImgFn) {
         // console.log(file[0]);
       const data = new FormData()
      let fileName = 'card/'
      data.append(
      'multipartFile', file[0]
      )
      data.append(
      'fileName', fileName
       )
       data.append(
      'flag', 'card'
        )

        uploadImage(data).then(res => {
      // console.log(res);
        let url = res.data
        insertImgFn(url)
     })
        },
      this.phoneEditor.create()
      if(this.addHtml){
        this.phoneEditor.txt.html(this.addHtml)
      }else{
          this.phoneEditor.txt.html()
      }
    },
      methods:{
        clear(){
           this.phoneEditor.txt.clear()
        }
      },
    watch:{
        addHtml:{
            handler:function(newVal){
            if(newVal!=''){
                setTimeout(()=>{
                    this.phoneEditor.txt.html(newVal)
                },0)
            }
            },
            deep: true, // 深度监听
            immediate: true, //立即执行
          },

    }
  }
</script>
<style>
font[size="1"] {
    font-size: 10px;
}
font[size="2"] {
    font-size: 11px;
}
font[size="3"] {
    font-size: 12px;
}
font[size="4"] {
    font-size: 13px;
}
font[size="5"] {
    font-size: 14px;
}
font[size="7"] {
    font-size: 15px;
}
font[size="6"] {
    font-size: 16px;
}
</style>
