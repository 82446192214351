<template>
  <div>
    <el-dialog
        v-model="createCardDialog"
        title="添加卡片"
        width="1000px"
        align-center
    >
      <div style="margin-right: 50px">
        <el-form :model="card" :rules="cardRules" ref="cardRules" label-width="120px" class="demo-ruleForm">
          <el-row>
            <el-col :span="8">
              <el-form-item class="itemWih" label="卡片名称" prop="name">
                <el-input v-model="card.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="itemWih" label="城市类型" prop="areaType">
                <el-select v-model="card.areaType">
                      <el-option label="中招体育" value="1"></el-option>
                      <el-option label="少年" value="2"></el-option>
                      <el-option label="高考体育" value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="itemWih" label="所属城市" prop="name">
                <el-select    v-model="card.areaId">
                  <el-option
                      style="width: 160px"
                      v-for="(item, index) in affiliationCampus"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="上架日期" prop="secKillTime">
                <el-date-picker  type="date" style="width:184px"
                                 format="YYYY-MM-DD"
                                 value-format="YYYY-MM-DD"
                                 v-model="card.secKillTime"
                                 placeholder="上架日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="下架日期" prop="soldEndTime">
                <el-date-picker  type="date" style="width:184px"
                                 format="YYYY-MM-DD"
                                 value-format="YYYY-MM-DD"
                                 v-model="card.soldEndTime"
                                 placeholder="下架日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="可视权限" prop="role">
                <el-select  v-model="card.role">
                  <el-option v-for="(item,index) in visual " :key="index"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
              <el-col :span="16">
                <el-form-item class="itemWih" label="卡片描述" prop="briefing">
                  <el-input style="width: 488px;" :rows="3" v-model="card.briefing"
                    prop="briefing"    placeholder="请输入描述信息" show-word-limit type="textarea"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item class="itemWih" label="缩略图" prop="miniPicture">
                  <el-upload
                        ref='upload'
                        class="upload"
                        :http-request="uploadCardOmit"
                        :before-upload="beforeAvatarUpload"
                        :on-remove="handleRemoveMin"
                        :class="{ uoloadSty: thisthisImageminiPicture == null ? true : false, disUoloadSty: thisthisImageminiPicture != null ? true : false }"
                        :limit=1
                        list-type="picture-card">
                     <el-button icon="Plus" text />
                    </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="16"
              >
              <el-form-item label="卡片规格" prop="isMoreSpec">
                <el-radio-group v-model="card.isMoreSpec" class="ml-4">
                <el-radio :label=1 size="large">单规格</el-radio>
                <el-radio :label=0 size="large">多规格</el-radio>
                <el-radio :label=3 size="large">单规格（组合卡）</el-radio>
             </el-radio-group>
              </el-form-item>
                <!-- <el-form-item class="itemWih" label="卡片描述" prop="briefing">
                  <el-input style="width: 488px;" :rows="3" v-model="card.briefing"
                    prop="briefing"    placeholder="请输入描述信息" show-word-limit type="textarea"
                  />
                </el-form-item> -->
              </el-col>
              <el-col :span="3">
                <el-form-item class="itemWih" label="详情图" prop="productPicture">
                  <el-upload
                        ref='upload'
                        class="upload"
                        :http-request="uploadCardProduct"
                        :before-upload="beforeAvatarUpload"
                        :on-remove="handleRemovePro"
                        :class="{ uoloadSty: thisImageproductPicture == null ? true : false, disUoloadSty: thisImageproductPicture != null ? true : false }"
                        :limit="1"
                        list-type="picture-card">
                     <el-button icon="Plus" text />
                    </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="createCardDialog = false">取消</el-button>
          <el-button type="primary" @click="confirmaddCard">确定</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
  import { uploadImage} from '@/api/helpManual'
  import {selectAreaByExpress} from "@/api/area";
  import {addCard}  from '@/api/card'
export default {
  name: "createCard",
  props:{ 
    visual:Object
  },
  data() {
    return {
      thisImageproductPicture:null,
      thisthisImageminiPicture:null,
      affiliationCampus:[],
      createCardDialog:false,
      card:{
        name:"",
        miniPicture:"",
        productPicture:"",
        areaId:null,
        secKillTime:null,
        soldEndTime:null,
        areaType:null,
        role:null,
        content:null,
        isMoreSpec:null,
        ruleId:1,
      },
      cardRules: {
        name: [{
          required: true,
          message: '请选择卡片名称！',
          trigger: 'blur'
        },],
        areaType: [{
          required: true,
          message: '请选择城市类型！',
          trigger: 'change'
        },],
        areaId: [{
          required: true,
          message: '请选择所属城市！',
          trigger:'change'
        }],
        isMoreSpec: [{
          required: true,
          message: '请选择规格！',
          trigger:'change'
        }],
        briefing: [{
          required: true,
          message: '请添加卡片描述！',
          trigger: 'blur'
        }],
        role: [{
          required: true,
          message: '请选择可视权限！',
          trigger: 'change'
        }],
        productPicture: [{
          required: true,
          message: '请添加详情图！',
          trigger: 'change'
        }],
        miniPicture: [{
          required: true,
          message: '请添加省略图！',
          trigger: 'change'
        }],
        secKillTime: [{
          required: true,
          message: '请选择上架日期！',
          trigger: 'change'
        }],
        soldEndTime: [{
          required: true,
          message: '请选择下架日期！',
          trigger: 'change'
        }],
      },
    }
  },
  created(){ 
    this.getAffiliationCampus()
  },
  methods: {
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = parseInt(y) + "-" + parseInt(m) + "-" + parseInt(d);
      return time;
    },
    handleRemoveMin(){ 
        this.thisthisImageminiPicture = null
    },
    handleRemovePro(){ 
        this.thisImageproductPicture = null
    },
    //上传省略图片
   async uploadCardOmit(file) {
    this.thisthisImageminiPicture = file.file
      this.card.miniPicture = await this.uploadImg(file)
    },
    async uploadCardProduct(file) {
      this.thisImageproductPicture = file.file
      this.card.productPicture = await this.uploadImg(file)
    },
    async uploadImg(file) {
      let url = null
      const data = new FormData()
      data.append(
        'multipartFile', file.file
      )
      data.append(
        'flag', 'card'
      )
      data.append(
        'fileName', file.file.name
      )
      await uploadImage(data).then(res => {
        if (res.code == 200) {
          url = res.data
        } else {
          this.$message.warning('图片上传失败');
        }
      })
      return url
    },

    //添加卡片
    confirmaddCard() {
      this.$refs.cardRules.validate((valid)=>{ 
        if(valid){ 
          this.card.secKillTime = this.dateFormat(this.card.secKillTime)
          this.card.soldEndTime = this.dateFormat(this.card.soldEndTime)
          addCard(this.card).then(res=>{ 
        if(res.code==200){ 
          this.createCardDialog = false
          this.$message.success("添加成功");
          this.$emit('success','success')
        }else{ 
          this.$message.error();("添加失败");
        }
      })
        }
      })
    },
    //获取所属校区
    getAffiliationCampus() {
      selectAreaByExpress().then(res => {
        if (res.code == 200) {
          this.affiliationCampus = res.data
        }
      })
    },
    openDialog() {
      this.createCardDialog = true;
    },

  }
}
</script>

<style scoped>
  .disUoloadSty  .el-upload--picture-card{
  display:none !important;   /* 上传按钮隐藏 */
}

  .el-upload--picture-card{
  width: 80px;
  height: 80px;
}
  .el-upload{
  width: 80px;
  height: 80px;
  line-height: 80px;
}
  .el-upload-list--picture-card .el-upload-list__item{
  width: 80px;
  height: 80px;
  line-height: 80px;
}
  .el-upload-list--picture-card .el-upload-list__item-thumbnail{
  width: 80px;
  height: 80px;
  line-height: 80px;
}
  .avatar{
  width: 80px;
  height: 80px;
}
</style>